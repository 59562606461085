import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Text } from '@twilio-paste/text';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { PlusIcon } from '@twilio-paste/icons/esm/PlusIcon';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/patterns/button-vs-anchor/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <Breadcrumb mdxType="Breadcrumb">
  <BreadcrumbItem to="/" mdxType="BreadcrumbItem">Home</BreadcrumbItem>
  <BreadcrumbItem to="/patterns" mdxType="BreadcrumbItem">Patterns</BreadcrumbItem>
      </Breadcrumb>
      <Heading as="h1" variant="heading10" mdxType="Heading">
  {props.pageContext.frontmatter.title}
      </Heading>
      <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
      <Box as="dl" display="flex" mdxType="Box">
  <Text as="dt" color="colorTextWeak" lineHeight="lineHeight30" mdxType="Text">
    <Box width="size10" mdxType="Box">Status</Box>
  </Text>
  <Text as="dd" mdxType="Text">beta</Text>
      </Box>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Best Practices for Button and Anchor Usage`}</h2>
        <p>{`Action hierarchy on a page should be easy and obvious for our users. To create effective user-centered experiences, it's important to follow user expectations and establish consistent UI action patterns. This is especially important when deciding when to use a button or an anchor (also known as a link).`}</p>
        <p>{`The `}<a parentName="p" {...{
            "href": "/patterns/create"
          }}>{`Create pattern`}</a>{` also includes helpful guidance on how to layout buttons, links, and organize page hierarchy.`}</p>
        <hr></hr>
        <h2>{`Ingredients`}</h2>
        <Box marginBottom="space80" mdxType="Box">
  <Stack orientation="horizontal" spacing="space30" mdxType="Stack">
    <Box marginBottom="space30" mdxType="Box">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/button/#primary-button" mdxType="Anchor">Primary button</Anchor>
        </Heading>
      </Card>
    </Box>
    <Box marginBottom="space30" mdxType="Box">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/button/#secondary-button" mdxType="Anchor">Secondary button</Anchor>
        </Heading>
      </Card>
    </Box>
    <Box marginBottom="space30" mdxType="Box">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/button/#link-style-button" mdxType="Anchor">Link-style button</Anchor>
        </Heading>
      </Card>
    </Box>
    <Box marginBottom="space30" mdxType="Box">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/button#destructive-button" mdxType="Anchor">Desctructive button</Anchor>
        </Heading>
      </Card>
    </Box>
    <Box marginBottom="space30" mdxType="Box">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/button#destructive-secondary-button" mdxType="Anchor">Secondary desctructive button</Anchor>
        </Heading>
      </Card>
    </Box>
    <Box marginBottom="space30" mdxType="Box">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/button#link-style-button" mdxType="Anchor">Link-style desctructive button</Anchor>
        </Heading>
      </Card>
    </Box>
    <Box marginBottom="space30" mdxType="Box">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/anchor" mdxType="Anchor">Anchor</Anchor>
        </Heading>
      </Card>
    </Box>
  </Stack>
        </Box>
        <h2>{`Usage`}</h2>
        <h3>{`General`}</h3>
        <p>{`From a high level, buttons are to be used for `}<strong parentName="p">{`performing an in-page`}</strong>{` action and anchors/links are for `}<strong parentName="p">{`navigating to a new page`}</strong>{`. You can read more about "why" on the `}<a parentName="p" {...{
            "href": "/components/button#button-vs-anchor-link"
          }}>{`Button`}</a>{` and `}<a parentName="p" {...{
            "href": "/components/anchor"
          }}>{`Anchor/Link`}</a>{` documentation.`}</p>
        <p>{`Because a button's functionality is distinctly different from an anchor, we need to be deliberate in their use. An example of this might be opening a link in a new tab; this action can't be performed by a button. If we make it look like a button, it can be confusing due to unmet user expectations and multiple `}<a parentName="p" {...{
            "href": "/components/button#accessibility"
          }}>{`accessibility concerns`}</a>{`.`}</p>
        <p>{`To determine the overall hierarchy on a page, you must prioritize which actions the user needs to perform first. Actions that are low effort or not as important should be a secondary button or link-style button. This means that clicking on an anchor should not add, change, or delete any data on the screen.`}</p>
        <h3>{`Do's and don'ts`}</h3>
        <h4>{`Accessibility`}</h4>
        <p>{`When we make buttons look like anchors and anchors look like buttons, we are telling users that they are the same thing, when they really aren't. Not only is this confusing, but there are a myriad of `}<a parentName="p" {...{
            "href": "/components/button#accessibility"
          }}>{`accessibility concerns`}</a>{` surrounding incorrect usage, too. Some tips for making sure you're using buttons and anchors the right way:`}</p>
        <ul>
          <li parentName="ul">{`Correctly choosing between an anchor or button element will help inform all users—but especially assistive technology users—what will happen next. Will I be taken to an entirely new page or will something happen on the current page?`}</li>
          <li parentName="ul">{`When using anchors that look like buttons, be very strategic in their use since voice dictation users may encounter issues when trying to activate them. A user may say, "Click the ‘Show more' button", but if that button is actually an anchor styled as a button, the dictation software won't be able to find it, since it can't tell what the anchor looks like visually. If an anchor that looks like a button is needed, make sure to `}<strong parentName="li">{`include an arrow icon`}</strong>{` to indicate to the user that they are being navigated to a new page.`}</li>
        </ul>
        <h2>{`Buttons`}</h2>
        <h3>{`Primary button`}</h3>
        <p>{`Use only one per page; this is the most important action a user needs to take. Optionally, you can add an icon to the left of the text to indicate important actions such as, "`}<strong parentName="p">{`+ Create new`}</strong>{`".`}</p>
        <p>{`If you decide to place an icon in a button, all button icons should be placed to the left. The only exception to this guidance is when you are moving the user forward (like in a multi-step modal, or when paginating through something). Only then should you use a "`}<strong parentName="p">{`Forward >`}</strong>{`" (ChevronRightIcon) button. Don't use an arrow icon, since those are reserved for anchors.`}</p>
        <LivePreview scope={{
          Button,
          PlusIcon,
          Stack
        }} language="jsx" mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space30">
  <Button variant="primary">
    Primary
  </Button>
  <Button variant="primary">
    <PlusIcon decorative />
    Create new
  </Button>
</Stack>`}
        </LivePreview>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1056px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.73529411764706%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAByklEQVQoz32S3W7TQBCFfVH6c1HxPgTeCPEcqIinQEiIC0RVCFSIlrpKi1sVUlFA3EFJ4p8667WdxF7vrIezGypaiFjp087M7p4ZH9lbvdHZWFnqfFhZurWPuLe2fMdhY9RAZyHLOFtf7fRurt22+IhP8eah96rb2w6Cb7z37iMfHJyxv9dn3+/z4eFnPgq+cvD+y0KOcfZ654yfb3/iZ90+7wc/oHHy1iOiTZ6vRlND5z8HdD4YkGmJUPsvj44auvd0QneflOrxqdN46QmRbxnDPJ1WNJtVfJEKDqOE4yTlURi72O5RfOFiSxTPawnu2Hw4irRVw3BdT8pyyyZVVZOBshAZa93MR24aW+e/l71nadv28p4TRH5dkMhwnNWcFhBSeISvHgsJMrdnMkdDyUo1TqyuFUtZ2NpiQZjI3+MZJ1nFIq+4mFQchgkPhqEDn8bDYcR5XjqxspxeWnJNcNPaD0GlydBI1I5EKiqmisZjQfCT4Jfb4SVBiLQmsr6nqaB0nClnRdu+8NDtzVV/bKum/ZPDBZjdOtDQcXXhd4AFbkA74Y4H9fuZLI6LYrKrtfaNIb8FNsYUyM0/wGx3bvfftV3EJ7VSD34BTC+4ZkLkp2AAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "table with a primary create new action button",
              "title": "table with a primary create new action button",
              "src": "/static/3ad9b2a2488ea40ade108eef8e8bf5bc/fd84e/button-primary-action.png",
              "srcSet": ["/static/3ad9b2a2488ea40ade108eef8e8bf5bc/e7c18/button-primary-action.png 408w", "/static/3ad9b2a2488ea40ade108eef8e8bf5bc/b4098/button-primary-action.png 816w", "/static/3ad9b2a2488ea40ade108eef8e8bf5bc/fd84e/button-primary-action.png 1056w"],
              "sizes": "(max-width: 1056px) 100vw, 1056px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <h3>{`Secondary button`}</h3>
        <p>{`Most actions on a page are secondary buttons. Use them for safe and easily reversible actions.`}</p>
        <p>{`There are some cases where a secondary button may be used for an irreversible action. An example of this would be a ‘Cancel' secondary button that may cause a customer to redo a long user flow. In this case, it should trigger an "Are you sure?" dialog or something similar. Check out the `}<a parentName="p" {...{
            "href": "/patterns/delete"
          }}>{`delete pattern`}</a>{` for more guidance. This would be best to test with your users.`}</p>
        <LivePreview scope={{
          Button
        }} language="jsx" mdxType="LivePreview">
  {`<Button variant="secondary">
  Secondary
</Button>`}
        </LivePreview>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1056px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.73529411764706%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAByklEQVQoz62Q32oTURDG96JpkoL4Pm58JvEtRPE5FFSwVFOKtJrEo43S1hJT8UJsY5tmk+xms//Pntn9nHPWFFtvPfCDmWHmm2+O1ajZD+tr9mF97U6vUWuJjfpd0VxviXrNFlxj7Gs0ud5ctwXPiVsNW9xutjRdjo+59tjaboudfv87Ou+OIMQAnc4Rer1j7H8c4lP/G/or9k8MPTFE9/0Q4sMJtne/4uXOAC/aX9Dtn7HGwa5FRJuoXl6URD9+ntLpaERAyeAf4hK0yEGc0NPDgu4/T+jes1g+GRiN15bnLbeKAojjhKTM4S8DhFGMMIwQ/CFJUkgpkWUSJTevkHlu4FgZR7lqW74fbukkTTNSvJeFUXAzOzfomn5BEGLhL01cltVJsV4WRaASRpDnbggqwuVkil/nlzhnxmMHF0wUJfA8H850blxmKcPXpHOPcTGaZaradENQO3KcOSbO7Aq9QLvW7nSuxfk0PpWQup7hwpOqcl4JbuqrWFCyQ5rOXGInxMIGFiH+U2KHNJlwHMbELonbKXEXhrHHtivBV9ZyGb7Bf3r8h3uW6y4eLPzgcxBEb5VSXXZZQX+xql/FqnutVyk9e5BJ+eg3og25+zJFA5AAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "table with a multiple action buttons",
              "title": "table with a multiple action buttons",
              "src": "/static/3a44056289a4510c87e0ed9e2febf0bf/fd84e/button-multiple-actions.png",
              "srcSet": ["/static/3a44056289a4510c87e0ed9e2febf0bf/e7c18/button-multiple-actions.png 408w", "/static/3a44056289a4510c87e0ed9e2febf0bf/b4098/button-multiple-actions.png 816w", "/static/3a44056289a4510c87e0ed9e2febf0bf/fd84e/button-multiple-actions.png 1056w"],
              "sizes": "(max-width: 1056px) 100vw, 1056px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <h3>{`Link-style button (tertiary)`}</h3>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">🚨 This is not a variant we recommend you use often.</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    When using buttons that look similar to anchors, <strong>be very strategic</strong> in their use since voice
    dictation users may encounter issues when trying to activate them by mistaking them for anchors.
  </CalloutText>
        </Callout>
        <p>{`If you need to add additional, less-visually competitive actions on a page, use a link-style button. If you've exhausted the use of primary and secondary buttons, a link-style button is the way to go.`}</p>
        <p>{`This can be used for the least important actions a user needs to take or when the use of multiple secondary buttons would be visually overwhelming. Given this information, you should weigh the risks between the UI control being unclear and the actions on the page being overwhelming.`}</p>
        <p>{`When making this decision, it's `}<strong parentName="p">{`a great opportunity to test`}</strong>{` which buttons you might need with your users.`}</p>
        <LivePreview scope={{
          Button,
          Stack
        }} language="jsx" mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space30">
  <Button variant="link">
    Link-style button
  </Button>
  <Button variant="destructive_link">
    Link-style button
  </Button>
</Stack>`}
        </LivePreview>
        <h2>{`Anchors`}</h2>
        <h3>{`Basic Anchor`}</h3>
        <p>{`For all anchors, the only functionality it supports is navigating. For this reason, an external link is generally the only icon that should be paired with anchors.`}</p>
        <LivePreview scope={{
          Anchor,
          Stack
        }} language="jsx" mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space30">
  <Anchor href="#">
    Anchor
  </Anchor>
  <Anchor href="#" showExternal>
    External anchor
  </Anchor>
</Stack>`}
        </LivePreview>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1056px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.73529411764706%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABi0lEQVQoz41S20oDMRDdh2rVT7Lo94nix4g3RGx1ZYtF6gWrIFVU2lpbbd1Lks0mkx0n6eIFFTpwdpKc5JyZYb25mcpquVQ5K5cWj2kdzM8uBQvl5cCu6Xxa+IRLerPu7e4Ee43GLR4dnmM9aKHvX2C12sR6vYWNk5upUA+u8LrVwcNa88ADgA2chCLAYDiE2/YdIOZg91MiKzS2vfE42jQGkTEBWgNynqJUOQ5ig2+JRjIkGJeNvVhEnuffOe0qUnrHC8Nk026kzIALgd1eH7v9IbafBjiO5eRhIcQYx97zi8Pr28hx1oTgBCl/CaapBOuYMOYg0/SrmqIiTXwUJxiGEQrxg/9P0GCn+4x394+U+9Q+/4QTpJE8dXrYvn9wnQjqiJO54PyH4IbtSAiZGZODbZ3cgXEOMacxkI/IyIo4+kIqpeNllkFK5yx1fFYIbnlRlOzjP2Enp4uW/4q8+DVUcYEEq95o9L7yHsanccxqSmtfKeVnBJsNaD832mXtuAmvfvM14ptU9doHG4S/0qlZBgIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "card layout with internal and external anchors",
              "title": "card layout with internal and external anchors",
              "src": "/static/fdc2d09f06b0a93054ae00aac17fa189/fd84e/button-card-external-anchor.png",
              "srcSet": ["/static/fdc2d09f06b0a93054ae00aac17fa189/e7c18/button-card-external-anchor.png 408w", "/static/fdc2d09f06b0a93054ae00aac17fa189/b4098/button-card-external-anchor.png 816w", "/static/fdc2d09f06b0a93054ae00aac17fa189/fd84e/button-card-external-anchor.png 1056w"],
              "sizes": "(max-width: 1056px) 100vw, 1056px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <h3>{`Anchors that look like buttons`}</h3>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">🚨 This is not a variant we recommend you use often.</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    When using anchors that look like buttons, be very strategic in their use as dictation software users may experience
    usability issues.
  </CalloutText>
        </Callout>
        <p>{`There are two variants of an anchor that look like a primary and secondary button. These are to be used when the primary action on the page is `}<strong parentName="p">{`navigating`}</strong>{` the user somewhere. When this is used, it must be accompanied by an arrow pointing to the right after the text. This helps to indicate that we are navigating the user to the page in the button.`}</p>
        <p>{`To create a button-styled anchor, use the Button component and add the as="a" prop so that it is rendered as an anchor semantically, while maintaining button styling.`}</p>
        <p>{`The same guidance applies here for any action deemed "primary": use only one per page. If you have a primary button and a primary anchor styled as a button, only use one. Be sure to research which action is most important for your users.`}</p>
        <p><strong parentName="p">{`Note:`}</strong>{` Anchors that look like Buttons don't have the same disabled or loading functionality.`}</p>
        <LivePreview scope={{
          Button,
          Stack
        }} language="jsx" mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space30">
  <Button as="a" href="#" variant="primary">
    Button as anchor
  </Button>
  <Button as="a" href="#" variant="secondary">
    Button as anchor
  </Button>
</Stack>`}
        </LivePreview>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1056px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.73529411764706%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB/UlEQVQoz5VR227TQBD1Q0sSIQTfQ8In8cArP1DERTwjhISQ2oIEiNISVJXSxK3ThiolVUuggBAE0sSO7cR2HHvtWR/GTiMReGLko53L7vGZGSU/X7yZmyse5OYub7OvFc5dyZCfL2mcn0FWP0Pqn88VtYuFknapUFIv5IuHufniHeV1WVuv109QrbxHrXYMtdqEqjah7Ryhvteawe5uC+rOB2wztFoLbyrHeLF+hGflJqp7P5ijsakQ0QomFpGM6VfnlDrdLpmWRZz7B4MI5MQTf/NzQtee+nT1iS8eNTOOV4ptO6tSAr4fUBCGMC0bQ8flcwB74MBxvCx23REc14M/GoHiGAkRZJJAkgSLijNFUVxWhkNvNQ2CIOS8ZJIhjL4F3TAhRISpJfw4rUv+ezLNcV3KLMoIuTZLmF7WjT66usGKx4hZSRAKdHQbgeAuk+xDEkUIPp7AeLiMb4vLSHQjI6SY/iAcTwhN00Svp2Ps+4hECBFzzo3ghTJTmbaYmnX3HvTrC2gv3IZRaUwUxpOWV9I743EoeCbU9ySZI0mGl1DPkZR2Ol0Ik2XnwUaN3i2+pOb9JWo/L9PjB2vibAprCg99A/9p7Z89fP2uQ9s/xJfWJ9id0+mc3yqWNbgxGLr7vMWtKI5V+gu8uRmIKFL57Qx4rls870YoxK3fzDOzBzzTMlIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "layout with anchors and anchors that look like buttons",
              "title": "layout with anchors and anchors that look like buttons",
              "src": "/static/00b6ffceddc73e9cf1fd9452917f3e1f/fd84e/button-anchor-like-buttons.png",
              "srcSet": ["/static/00b6ffceddc73e9cf1fd9452917f3e1f/e7c18/button-anchor-like-buttons.png 408w", "/static/00b6ffceddc73e9cf1fd9452917f3e1f/b4098/button-anchor-like-buttons.png 816w", "/static/00b6ffceddc73e9cf1fd9452917f3e1f/fd84e/button-anchor-like-buttons.png 1056w"],
              "sizes": "(max-width: 1056px) 100vw, 1056px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <h3>{`Anchors in text`}</h3>
        <p>{`Anchors should be the `}<strong parentName="p">{`ONLY`}</strong>{` thing we underline in text, because underlined static text could be mistaken for a link. Links can be used within bodies of text to indicate the presence of related content. If you're linking to an external page, use the `}<a parentName="p" {...{
            "href": "/components/anchor#external-anchor"
          }}>{`external anchor`}</a>{`.`}</p>
        <LivePreview scope={{
          Anchor,
          Paragraph
        }} language="jsx" mdxType="LivePreview">
  {`<Paragraph>
  For further information, assistance, or help, <Anchor href="#">contact our new business development team</Anchor>. We're experienced in delivering messages to each of these countries, and will assist you in making a success of your messaging program.
</Paragraph>`}
        </LivePreview>
        <h2>{`Destructive Buttons and Anchors`}</h2>
        <p>{`The destructive variants of the button and anchors should follow the same guidance as the non-destructive variants.`}</p>
        <h2>{`Final tips`}</h2>
        <ul>
          <li parentName="ul">{`Pairing a button label with an icon reinforces meaning and quickens recognition. Use at your discretion.`}</li>
          <li parentName="ul">{`All buttons and links should be written as succinctly as possible and make sense on their own (e.g., don't write "Click here"). This is especially important for users of assistive technology who often navigate through a list of all actions on a page, meaning they might not know about any contextual information surrounding a given action.`}</li>
        </ul>
        <h2>{`Starter kits`}</h2>
        <h3>{`CodeSandbox`}</h3>
        <p>{`Coming soon`}</p>
        <h3>{`Figma`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.figma.com/file/E6KUvMhioUmAgN0nwmReTM/Paste-Components?node-id=453%3A1630"
            }}>{`Anchor`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.figma.com/file/E6KUvMhioUmAgN0nwmReTM/Paste-Components?node-id=400%3A851"
            }}>{`Button`}</a></li>
        </ul>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      